<template>
  <div class="default-wrapper wrapper">
    <div class="not-found">
      {{ appData }}
      loading...
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pages',
  data() {
    return {
      appData: undefined,
      start  : undefined,
      code   : undefined
    }
  },
  methods: {
    getCategory(id) {
      return this.$store.getters.getCategories.find(i => i.id === id);
    },
    getItem(id) {
      return fetch(`/public/item/${id}`).then(response => response.json());
    },
    openItem() {
      let fbc = undefined;
      // let fbp = undefined;
      if (this.start.includes('_fbclid')) {
        let idx    = this.start.indexOf('_fbclid');
        fbc        = this.start.slice(idx + '_fbclid'.length);
        this.start = this.start.slice(0, idx);
        localStorage.setItem('_fbc', fbc);
      }
      // if (this.start.includes('_fb')) {
      //   let idx    = this.start.indexOf('_fb');
      //   fbp        = this.start.slice(idx + 1);
      //   this.start = this.start.slice(0, idx);
      //   localStorage.setItem('_fbp', fbp.replaceAll(".", "_"));
      // }
      let dataSplit  = this.start.split('_');
      let [id, code] = dataSplit;
      if (code) {
        this.code = code;
      }
      let hex    = id.substring(1);
      // console.log(this.start);
      let itemID = parseInt(hex, 16);
      this.getItem(itemID).then(data => {
        let c    = this.getCategory(data.category);
        let cURL = 'home';
        if (c) {
          cURL = c.info.url;
        }
        if (!this.code) {
          this.code = 't' + itemID;
        }

        this.$Code(this.code);
        this.$router.push({name: 'ItemOrServiceItem', params: {category: cURL, id: itemID}});
      })
    },
    openCategory() {
      let hex         = this.start.substring(1)
      let category_id = parseInt(hex, 16)
      let c           = this.getCategory(category_id)
      let cURL        = 'home'
      if (c) {
        cURL = c.info.url
      }
      if (!this.code) {
        this.code = 'c' + category_id
      }
      this.$Code(this.code)
      this.$router.push({name: 'Category', params: {category: cURL}})
    },
    openPage() {
      this.$router.push(this.start)
    }
  },
  mounted() {
    if (window.opened) {
      window.Telegram.WebApp.close();
      return
    }
    window.opened = true;
    this.appData  = window.location.href;
    // console.log(window.Telegram.WebApp)
    if (window.Telegram?.WebApp.initDataUnsafe) {
      this.start = window.Telegram.WebApp.initDataUnsafe.start_param;
    }
    if (this.start) {
      if (this.start[0] === 'k') {
        this.code  = this.start.substring(1, 9);
        this.start = this.start.substring(9);
      }
      if (this.start[0] === 't') this.openItem();
      else if (this.start[0] === 'c') this.openCategory();
      else this.openPage();
    } else {
      this.$router.push({name: 'home'})
    }
  }
}
</script>
