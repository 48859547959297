<template>
  <div class="personal-order-list-item" v-if="lead">

    <div class="list-item-top" data-toggle="collapse" :data-target="'#order' + lead.id" aria-expanded="false"
      :aria-controls="'#order' + lead.id">
      <div class="date_status" :class="getLeadStatus(lead)">
        <div class="date_status-body">
          <span class="date">
            <span class="order-number">{{ $static('cabinet.lead-id', 'Заявка') }} #{{
              lead.id
            }}</span> | {{ new Date(lead.created_at).toLocaleDateString() }}
          </span>
          <div class="status-bar">
            <span class="status">{{ leadStatusList[status] }}</span>
          </div>
        </div>
        <div class="price-element">{{ lead.total }} {{ $store.getters.getSettings.symbol }}</div>
      </div>
      <div class="price-box">
        <div class="price-element">{{ lead.total }} {{ $store.getters.getSettings.symbol }}</div>
      </div>
      <div class="product-previews">
        <div class="preview-count" v-if="plusBusked > 0">+{{ plusBusked }}</div>
        <div class="product-preview bg-cover image" v-for="(item, index) in slicedBusked" :key="index"
          :style="{ 'background-image': $filters.getItemImage(item.preview) }"></div>
      </div>
      <div class="icons-holder">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 7.5L10 12.5L15 7.5" stroke="#212121" />
        </svg>
      </div>
    </div>
    <!-- full information about order-->
    <div class="collapse" :id="'order' + lead.id">
      <div class="personal-order-list-item-body">
        <div class="personal-order-list-item-holder">

          <div class="personal-order-product-wrapper">
            <!-- order product item-->
            <div class="cart-item" v-for="(item, index) in busked" :key="index">
              <div class="item-holder">
                <div class="item-info">
                  <div class="image bg-cover" :style="{ 'background-image': $filters.getItemImage(item.preview) }">
                  </div>
                  <div class="right-side">
                    <a href="#" class="product-name">{{ item.title }}</a>
                    <div class="additional" v-if="item.u_select?.length">
                      <div class="additional-line" v-for="(o, s_index) in item.u_select" :key="s_index">
                        <div class="add-name">{{ o.item.name }}:</div>
                        <div class="value" v-if="o.item.price">{{ o.item.price }} {{ $store.getters.getSettings.symbol
                          }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="option-box">
                  <div class="item">
                    <div class="item-name">{{ $static('cabinet.order-price', 'Ціна') }}:</div>
                    <div class="value">{{ item.price }} {{ $store.getters.getSettings.symbol }}</div>
                  </div>
                  <div class="item text-right">
                    <div class="item-name">{{ $static('cabinet.order-total', 'Сума') }}:</div>
                    <div class="value">{{ item.count }} x {{ itemTotal(item) }} {{ $store.getters.getSettings.symbol }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end order product item-->
            <!-- order list item actions-->
          </div>
        </div>
        <div class="personal-order-list-item-footer ">
          <div class="panel-bordered footer-holder">
            <div class="left-item-footer">
              <div class="order-related-info">
                <ul>
                  <li v-if="lead.info.name">
                    <div class="name">Ім'я</div>
                    <div class="value">{{ lead.info.name }}</div>
                  </li>

                  <li v-if="lead.info.phone">
                    <div class="name">Телефон</div>
                    <div class="value">{{ lead.info.phone }}</div>
                  </li>

                  <li v-if="lead.info.email">
                    <div class="name">Email</div>
                    <div class="value">{{ lead.info.email }}</div>
                  </li>

                  <li v-if="lead.info.date">
                    <div class="name">Обрана дата</div>
                    <div class="value">{{
                      new Date(lead.info.date * 1000).toLocaleDateString('uk-UA', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric'
                      }) }}</div>
                  </li>

                  <template v-if="lead.info.fields && lead.info.fields.length > 0">


                    <li v-for="(field, fieldIndex) in lead.info.fields" :key="fieldIndex">
                      <template
                        v-if="field?.manifest?.type === 'file' && Array.isArray(field.data) && field.data?.length > 0">
                        <label class="custom-label">{{ field.manifest.name || `Додаткове поле
                          №${fieldIndex + 1}`
                          }}</label>

                        <div class="files-uploader default-files-uploader">
                          <div class="uploader-previews">

                            <div class="image bg" width="40px" height="40px"
                              v-for="(requestImg, requestImgIndex) in field.data" :key="'requstImg' + requestImgIndex"
                              :style="{
                                backgroundImage: isImageFile(requestImg) ? `url('${requestImg}')` : `url('/images/file_placeholder.png')`
                              }">
                              <div>
                                <Popper v-if="isImageFile(requestImg)" arrow content="Відкрити" hover placement="top"
                                  style="white-space: nowrap">
                                  <a width="5px" height="5px" data-fancybox="gallery"
                                    class="btn-icon btn-remove btn-small" :href="requestImg"
                                    style="bottom: 3px;top: inherit;left: 3px;">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5 7.5L10 12.5L15 7.5" stroke="#212121" />
                                    </svg>
                                  </a>
                                </Popper>

                                <Popper v-if="!isImageFile(requestImg)" arrow content="Завантажити" hover
                                  placement="top" style="white-space: nowrap">
                                  <button class="btn-icon btn-remove btn-small"
                                    @click="downloadServiceRequestFile(requestImg)"
                                    style="bottom: 3px;top: inherit;right: 3px;">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5 7.5L10 12.5L15 7.5" stroke="#212121" />
                                    </svg>
                                  </button>
                                </Popper>
                              </div>

                            </div>
                          </div>
                        </div>
                      </template>

                      <template v-else-if="typeof field.data === 'number' || field.data?.length > 0">
                        <div class="name">{{ field.manifest.name }}</div>
                        <div class="value">{{ field.data }}</div>
                      </template>
                    </li>
                  </template>

                  <template v-if="lead.info.orderPageComment">
                    <li>
                      <div class="name">Коментар</div>
                      <div class="value">{{ lead.info.orderPageComment }}</div>
                    </li>
                  </template>
                </ul>
              </div>

            </div>
            <!-- <div class="right-item-footer">
              <div class="billing-info">
                <ul>
                  <li>
                    <div class="name">{{ $static('cabinet.total-busked', 'Замовлення') }}</div>
                    <div class="value">{{ totalBusked }} {{ $store.getters.getSettings.symbol }}</div>
                  </li>
                  <li v-if="lead.info.promo_discount">
                    <div class="name">{{ $static('cabinet.promo-discount', 'Знижка по промокоду') }}</div>
                    <div class="value">{{ order.info.promo_discount }}{{ $store.getters.getSettings.symbol }}</div>
                  </li>
                  <li v-if="lead.info.bonus_discount">
                    <div class="name">{{ $static('cabinet.bonus-discount', 'Використані бонусів') }}</div>
                    <div class="value">{{ lead.info.bonus_discount }} {{ $store.getters.getSettings.symbol }}</div>
                  </li>
                  <li v-if="order.info.delivery_price">
                    <div class="name">{{ $static('cabinet.del-price', 'Ціна доставки') }}</div>
                    <div class="value">{{ lead.info.delivery_price }} {{ $store.getters.getSettings.symbol }}</div>
                  </li>
                  <li class="total">
                    <div class="name">{{ $static('cabinet.total-total', 'ВСЬОГО') }}:</div>
                    <div class="value">
                      <div class="price-element">{{ lead.total }} {{ $store.getters.getSettings.symbol }}</div>
                    </div>
                  </li>
                </ul>
              </div>-->
          </div>
        </div>
      </div>

      <!-- <div class="comment-curier-area" v-if="lead?.info?.comment">
          <div class="label-custom">{{ $static('cabinet.curier-comment', 'Коментар') }}</div>
          <div class="text">{{ lead.info.comment }}</div>
        </div>
      </div> -->
    </div>
    <!-- end full information about order-->
  </div>

  <!-- <Teleport to="body">
    <div class="modal custom-modal text-center modal-bonus fade" :id="'modal_bonus' + lead.id" tabindex="-1"
      role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" v-if="bonusStatus">
          <div class="modal-header" style="background-color: #fff !important;"></div>
          <div class="modal-body" style="text-align: center">
            <div class="getted-bonus">
              <h2>{{ $static('cabinet.bonus_confirm', 'Бонуси нараховано!') }} </h2>
              <div class="coin">
                <img src="/images/coin.png" alt="Бонуси">
                <div class="bonus-panel" v-if="account">
                  <span>Ваші бонуси: <span class="num">{{ MyBonus }}</span></span>
                </div>
              </div>
              <div class="button-holder">
                <button type="button" class="default-btn btn-outline" data-dismiss="modal" aria-label="Close">
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content" v-else>
          <div class="modal-header" style="background-color: #fff !important;"></div>
          <div class="modal-body">
            <div class="delivery-laps" style="text-align: center">
              <div class="coin"><img src="/images/coin.png" alt="Бонуси"></div>
              <p>{{ $static('cabinet.bonus_text', 'Щоб нарахувати бонуси вам потрібно спочатку сплатит за товар') }}
              </p>
              <div class="button-holder">
                <button type="button" class="default-btn btn-outline" data-dismiss="modal" aria-label="Close">
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </Teleport> -->
</template>

<script>
export default {
  name: 'LeadHistoryItemRow',
  props: ['lead'],
  data() {
    return {
      leadStatusList: {},
      bonusStatus: false
    }
  },
  computed: {

    account() {
      return this.$store.getters.getAccount
    },
    MyBonus() {
      return this.account?.bonus ? this.account.bonus : 0
    },
    bonus() {
      return this.lead.info.bonus
    },
    bonus_ok() {
      return this.bonusStatus || this.lead.info.bonus_ok || (this.lead.info.bonus === 0)
    },
    delivery() {
      return this.$store.getters.getDelivery
    },
    surname() {
      return this.lead?.info?.surname ? this.lead.info.surname : ''
    },
    phone() {
      return this.lead?.info?.phone ? this.lead.info.phone : ''
    },
    busked() {
      return this.lead?.info?.busked ? this.lead.info.busked : []
    },
    totalBusked() {
      return this.busked?.reduce((prev, next) => this.itemTotal(next) + prev, 0)
    },
    phoneValid() {
      return this.phone.startsWith('38') ? '+' + this.phone : this.phone
    },
    plusBusked() {
      return this.busked.length - 2
    },
    slicedBusked() {
      return this.busked.slice(0, 2)
    },
    delivery_type() {
      return this.lead?.delivery
    },
    status() {
      return this.lead?.status
    }
  },

  mounted() {
    let leadStatuses = [
      { label: this.$static('lead-slist.new', 'Нові'), value: 'new' },
      { label: this.$static('lead-slist.process', 'В обробці'), value: 'process' },
      { label: this.$static('lead-slist.complete', 'Завершені'), value: 'complete' },
      { label: this.$static('lead-slist.cancel', 'Відмінені'), value: 'cancel' }
    ];
    leadStatuses.forEach(leadStatus => {
      this.leadStatusList[leadStatus.value] = leadStatus.label;
    });
  },
  methods: {
    getMyBonus(id) {
      fetch('/public/get_bonus', { method: 'POST', body: JSON.stringify({ id: this.lead.id }) })
        .then(response => response.json())
        .then(json => {
          this.bonusStatus = json.status
          if (json.bonus) {
            this.$store.commit('setBonus', json.bonus)
          }
          let showBonus = document.querySelector(`a[href="#modal_bonus${this.lead.id}"]`)
          if (showBonus) {
            showBonus.click()
          }
        })
    },
    showDeliveryMap() {
      window.showDeliveryMapModal(this.lead.info.delivery)
    },
    itemTotal(item) {
      return (item.price * item.count) + this.addition_price(item) + this.addPrice(item)
    },
    addition_price(item) {
      return item?.add && item.add.length ? item.add.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    addPrice(item) {
      return item.u_select && item.u_select.length ? item.u_select.reduce((prev, next) => next.item?.price ? next.item?.price : 0 + prev, 0) : 0
    },
    getLeadStatus(lead) {
      switch (lead.status) {
        case 'cancel':
          return 'order_canceled'
        case 'progress':
          return 'order_send'
        case 'process':
          return 'order_proccess'
        default:
          return 'order_success'
      }

    },
    reorder(lead) {
      let busked = lead.info.busked;
      this.$store.commit('setBusked', busked.map(i => {
        i.additionSelect = []
        i.additionUSelect = []
        return i
      }));
      this.$router.push({ name: 'Order' })
    },

    buskedPreview(lead) {
      return lead.info.busked.slice(0, 2)
    },

    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },

    downloadServiceRequestFile(fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', '');
      // link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    isImageFile(filename) {
      if (typeof filename !== 'string') {
        return false;
      }
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff', 'jfif'];
      const extension = filename.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    }
  }
}
</script>

<style>
.files-uploader {
  width: 100%;
  margin-bottom: 20px;
  display: block;
  position: relative;
  border: 1px dashed black;
  border-radius: 7px;

  &.size-grid-uploader {
    margin: 0;

    .files-uploader-holder {
      padding: 8px;
    }

    p {
      margin-top: 0;

      .icon {
        margin-left: 5px;
      }
    }
  }

  .uploader-previews {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    display: table;
    width: 100%;

    .image {
      width: 70px;
      height: 70px;
      border-radius: 7px;
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;
      border: 1px solid black;

      &.image-placeholder {
        background-color: #dfdfdf;
        border-color: #dfdfdf;
        color: #828282;
        text-align: center;
        font-size: 11px;
        font-weight: 700;
      }

      &:hover {
        .btn-icon {
          opacity: 1;
          visibility: visible;
        }
      }

      .btn-icon {
        position: absolute;
        right: 6px;
        bottom: 6px;
        opacity: 0;
        visibility: hidden;
        min-width: 24px;

        &.btn-remove {
          background-color: red;
          color: white;
        }
      }
    }
  }

  .files-uploader-holder {
    width: 100%;
    line-height: 1;
    padding: 20px;

    span {
      font-size: 12px;

      &.icon {
        color: black;
        opacity: 1;
        font-size: 22px;
      }
    }
  }
}

.uploader-previews {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  display: table;
  width: 100%;

  .image {
    width: 70px;
    height: 70px;
    border-radius: 7px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid black;

    &.image-placeholder {
      background-color: #dfdfdf;
      border-color: #dfdfdf;
      color: #828282;

      text-align: center;
      font-size: 11px;
      font-weight: 700;
    }

    &:hover {
      .btn-icon {
        opacity: 1;
        visibility: visible;
      }
    }

    .btn-icon {
      position: absolute;
      right: 6px;
      bottom: 6px;
      opacity: 0;
      visibility: hidden;
      min-width: 24px;

      &.btn-remove {
        background-color: red;
        color: black;
      }
    }
  }
}
</style>