<template>
  <p> {{ manifest.name }}</p>
  <div class="uploader-holder">

    <label class="modal-review__photo-upload-btn-immitator" :for="fieldIndex + 'review-photo-input'">
      <template v-if="isUserLoggedIn || isTelegram">
        <div class="modal-review__photo-upload-content-wrapper">
          <svg class="modal-review__upload-photo-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10"
            viewBox="0 0 10 10" fill="none">
            <g clip-path="url(#clip0_1792_1069)">
              <path
                d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z"
                fill="#14F9A7" />
              <path d="M5 2.6001V7.6001" stroke="black" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M7.5 5H2.5" stroke="black" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_1792_1069">
                <rect width="10" height="10" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>{{ $static('service-request.load-files', 'Завантажити файли або зображення')
            }}</span>
        </div>
      </template>
      <template v-else>
        <div class="modal-review__photo-upload-content-wrapper">
          <span>Для можливості завантажити файли або зображення необхідно авторизуватися</span>
        </div>
      </template>

      <input type="file" :name="fieldIndex + 'review-photo-input'" :id="fieldIndex + 'review-photo-input'"
        :accept="'*/*'" :multiple="!isTelegram" :ref="'fileInput' + fieldIndex" @change="addReviewPhotos"
        @click="$emit('resetErrors');" :disabled="!isUserLoggedIn && !isTelegram">
      <p class="help-text" v-for="(err, errIndex) in photoUploader.errors" :key="errIndex">
        {{ err.error }}
      </p>
    </label>

    <div>
      <p class="leave-comment-сall-to-action small_mb">
        {{ $static('service-request.max-files', '* Доступно до завантаження, шт: ') }}{{
          availableFilesQuantity
        }}</p>
      <p class="leave-comment-сall-to-action small_mb">
        {{ $static('service-request.max-file-size', '* Максимальний розмiр файлу: 2 МБ ') }}</p>
      <p v-if="isTelegram" class="leave-comment-сall-to-action small_mb">
        <strong>* Завантажуйте по одному файлу!</strong>
      </p>
    </div>

    <div class="result-upload">
      <div class="upload-item" v-for="(photo, index) in photoUploader.filesMetadata" :key="index">
        <div class="upload-preview bg-cover"
          :style="{ 'background-image': 'url(' + photoUploader.preview[index] + ')' }">
        </div>
        <div class="upload-info">
          <div class="prev-name">{{ photo.name }}</div>
          <div class="prev-size">{{ $filters.humanFileSize(photo.size) }}</div>
        </div>
        <a class="remove" @click="removeReviewPhoto(index)"><img src="/images/icons/delete.svg" alt=""></a>
      </div>
    </div>
  </div>
</template>

<script>
// :add-review-photos="addReviewPhotos(event,index)"  :photo-uploader="photoUploader" :remove-review-photo="removeReviewPhoto(index)"
export default {
  name: 'ImageUploader',
  props: ['modelValue', 'manifest', 'fieldIndex', 'resetFileInputPreview'],
  emits: ['update:modelValue', 'fileUploaderError', 'resetErrors'],
  data() {
    return {
      photoUploader: {
        // errors: [],
        files: [],
        filesMetadata: [],
        preview: [],
      },
    }
  },
  watch: {
    resetFileInputPreview(newVal) {
      if (newVal) {
        this.photoUploader.files = [];
        this.photoUploader.filesMetadata = [];
        this.photoUploader.preview = [];
        this.$refs['fileInput' + this.$props.fieldIndex].value = "";
      }
    }
  },
  computed: {
    isTelegram() {
      if (window.Telegram && window.Telegram?.WebApp && window.Telegram.WebApp.initData && window?.Telegram?.WebApp?.initData.length > 0) { return true }
      else { return false }
    },
    isUserLoggedIn() {
      return this.$store.getters.getUserInfo ? true : false;
    },
    fileCount() {
      return this.$store.getters.getFileCount;
    },
    maxFiles() {
      return Number(this.fileCount.max_file);
    },
    totalUserFiles() {
      return Number(this.fileCount.lead);
    },
    availableFilesQuantity() {
      return (this.maxFiles - this.totalUserFiles) >= 0 ? this.maxFiles - this.totalUserFiles : 0;
    },

  },
  methods: {
    async addReviewPhotos(event) {
      this.$emit('resetErrors');
      this.photoUploader.errors = [];

      let reviewPhotos = [...event.target.files].filter((value, index, array) => array.findIndex(x => x.name === value.name) === index);

      if ((this.photoUploader.files.length + reviewPhotos.length) > this.maxFiles) {
        event.target.value = null;
        this.$emit('fileUploaderError', 'maxFilesError');
        return;
      }

      for (const revPhoto of reviewPhotos) {
        try {
          // Загрузка файла через Telegram API
          const photoStorageURL = await this.sendReviewPhoto(revPhoto);

          const reader = new FileReader();
          const promise = new Promise((resolve) => {
            reader.onload = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(revPhoto);
          });

          this.photoUploader.preview.push(await promise);
          this.photoUploader.files.push(photoStorageURL);  // Сохраняем URL загруженного файла
          this.$emit('update:modelValue', this.photoUploader.files);
          this.photoUploader.filesMetadata.push(revPhoto);

        } catch (error) {
          this.$emit('fileUploaderError', 'filesUploadError');
          return;
        }
      }
    },

    async sendReviewPhoto(file) {
      const formData = new FormData();

      formData.append('file', file);

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.addEventListener('load', () => {
          if (xhr.status === 413) {
            this.$emit('fileUploaderError', 'tooBigFileError');
          }

          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const response = JSON.parse(xhr.responseText);
              if (response.error) {
                if (response.error === 'not auth') {
                  this.$emit('fileUploaderError', 'notAuthForUploadError');
                } else if (response.error === 'too much files') {
                  this.$emit('fileUploaderError', 'maxFilesError');
                }
                reject(`Ошибка загрузки файла ${file.name}: ${response.error}`);
              } else {
                this.$store.commit('setFileCount', { lead: Number(response.files) });
                resolve(response.url);
              }
            } catch (parseError) {
              this.$emit('fileUploaderError', 'filesUploadError');
              reject(`Ошибка разбора JSON: ${parseError}`);
            }
          } else {
            this.$emit('fileUploaderError', 'filesUploadError');
            reject(`Ошибка загрузки файла: ${xhr.statusText}`);
          }
        });

        xhr.addEventListener('error', () => {
          this.$emit('fileUploaderError', 'filesUploadError');
          reject('Сетевая ошибка при загрузке файла');
        });

        xhr.addEventListener('abort', () => {
          this.$emit('fileUploaderError', 'filesUploadError');
          reject(`Загрузка файла ${file.name} была прервана`);
        });

        // Открываем запрос
        xhr.open('POST', '/public/lead/upload');

        // Добавляем заголовок initData если зашли с мобильного telegram
        if (!this.isTelegram) {
          formData.append('user', JSON.stringify(this.$store.getters.getUserInfo));
        } else {

          if (window?.Telegram?.WebApp?.initData) {
            xhr.setRequestHeader('initData', window?.Telegram?.WebApp?.initData);
            formData.append('user', JSON.stringify(this.$store.getters.getUserInfo));
          }
        }

        // Отправляем запрос с файлом
        xhr.send(formData);
      });
    },
    async removeReviewPhoto(photoIdx) {
      try {
        const headers = this.isTelegram ? {
          'Content-Type': 'application/json',
          'initData': window?.Telegram?.WebApp?.initData
        } : {
          'Content-Type': 'application/json',
        }

        const user = this.$store.getters.getUserInfo || {};
        const url = this.photoUploader.files[photoIdx];

        let response = await fetch('/public/lead/rm_file', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ user, url })
        });
        response = await response.json();

        if (!response.found) {
          throw new Error('Файл не найден')
        }

        this.$store.commit('setFileCount', { lead: response.files });
        this.photoUploader.files.splice(photoIdx, 1);
        this.$emit('update:modelValue', this.photoUploader.files);
        this.photoUploader.filesMetadata.splice(photoIdx, 1);
        this.photoUploader.preview.splice(photoIdx, 1);

        this.$refs['fileInput' + this.$props.fieldIndex].value = "";
        // this.createdReviewData.photo.splice(photoIdx, 1);
        this.photoUploader.errors = [];
      } catch (error) {
        this.$emit('fileUploaderError', 'filesUploadError');
        console.error(`Ошибка при выполнении fetch: ${error.message}`);
      }
    },
  }
}
</script>
<style scoped>
.videoBox video {
  max-width: 100%;
  max-height: 100%;
  z-index: 11;
  width: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
}

.leave-comment-сall-to-action {
  margin-bottom: 14px;
  text-align: left;
  color: #797979;
  font-size: 12px;
  font-weight: 400;
  line-height: 9px;
}

.small_mb {
  margin-bottom: 4px;
}
</style>