<template>
  <div class="catalog-wrapper wrapper">

    <!--    <div class="custom-header category-header bg-cover" v-if="categoryImage"-->
    <!--         :style="{'background-image': $filters.getItemImage(categoryImage)}">-->
    <!--      &lt;!&ndash;      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>&ndash;&gt;-->
    <!--    </div>-->
    <div class="custom-header category-header" v-if="categoryImage">
      <img :src="categoryImage" alt="">
    </div>
    <div class="category-header custom-header min-header" v-else>
      <!--      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
    </div>
    <div class="cat-title">
      <h1>{{ $dynamic(categoryObject, categoryName, 'name') }}</h1>
    </div>
    <div class="sorting-panel" v-if="tags && tags.length || (groups && groups.length)">
      <div class="sort-list">
        <div class="sorting-checkbox">
          <input id="all" type="checkbox" v-model="all_tags" value="1">
          <label for="all">
            <span class="text">{{ $static('options.all', 'Усі') }}</span>
          </label>
        </div>

        <div class="sorting-checkbox" v-for="(op, index) in short_tags" :key="index">
          <input v-if="op" :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
          <label v-if="op" :for="op.id">
            <span class="pick" v-if="op.image" :style="{ 'background-image': $filters.getItemImage(op.image) }"></span>
            <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
            <span class="text">
              <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
              <span class="custom-option-price">
                <span class="price" v-if="op.price">
                  {{ $filters.money(op.price) }} {{ $store.getters.getSettings.symbol }}
                </span>
                <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }} {{
                  $store.getters.getSettings.symbol
                }}</span>
              </span>
            </span>
          </label>
        </div>
        <div class="filter-trigger-holder" v-if="tags && tags.length > settings?.labelQuantity || groups?.length">
          <a href="javascript:void(0);" @click="toggleFilter" class="filter_trigger">
            <span class="text">Відкрити фільтр</span>
            <img src="/images/icons/filter.svg" alt="">
          </a>
        </div>
      </div>
    </div>

    <div class="catalog-grid-holder">
      <ul class="catalog-grid" :class="{ 'double-items': $store.getters.getSettings.catalog_duo }">
        <CatalogItemPreview v-for="(item, index) in items" :key="index" :item="item" :categoryObject="categoryObject"
          @leaveLead="handleLeaveLeadBtnClick" />
        <div ref="loadMore"></div>
      </ul>
    </div>
  </div>

  <div class="fixed-filter-trigger">
    <a href="javascript:void(0);" @click="toggleFilter" class="filter_trigger">
      <img src="/images/icons/filter.svg" alt="">
    </a>
  </div>

  <div class="filter_sidebar" :class="{ 'opened': filterState }">
    <div class="filter_sidebar_wrapper">
      <div class="panel-top">
        <div class="panel-top-name">{{ $static('catalog.filter', 'Фільтри') }}</div>
        <a href="javascript:void(0);" @click="toggleFilter" class="close_filter">
          <img src="/images/icons/close.svg" alt="">
        </a>
      </div>

      <div class="panel-middle">
        <div class="filter-list">
          <div class="filter-holder">

            <div class="filter-panel">
              <div class="sorting-panel" v-if="(tags && tags.length)">
                <div class="sort-list">
                  <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
                    <input v-if="op" :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                    <label v-if="op" :for="op.id">
                      <span class="pick" v-if="op.image"
                        :style="{ 'background-image': $filters.getItemImage(op.image) }"></span>
                      <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                      <span class="text">
                        <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
                        <span class="custom-option-price">
                          <span class="price" v-if="op.price"> {{ $filters.money(op.price) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                          <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="sorting-panel" v-for="(group, gIndex) in groups" :key="gIndex">
                <div class="custom-label">
                  {{ $dynamic(group, group.name, 'name') }}
                </div>
                <div class="sort-list">
                  <div class="sorting-checkbox" v-for="(op, index) in group.list" :key="index">
                    <input v-if="op" :id="gIndex + '_' + op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                    <label v-if="op" :for="gIndex + '_' + op.id">
                      <span class="pick" v-if="op.image"
                        :style="{ 'background-image': $filters.getItemImage(op.image) }"></span>

                      <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                      <span class="text">
                        <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
                        <span class="custom-option-price">
                          <span class="price" v-if="op.price"> {{ $filters.money(op.price) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                          <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="panel-bottom">
        <button href="javascript:void(0)" @click="toggleFilter" class="default-btn reload_filter">
          <span class="text">Скинути</span>
          <img src="/images/icons/refresh.svg">
        </button>
        <button href="javascript:void(0)" @click="toggleFilter" class="default-btn btn-accent apply-filter">
          <span class="text">Застосувати</span>
        </button>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <div class="frame-modal-wrapper-bg filter-modal-wrapper-bg" @click="toggleFilter"
      :class="{ 'is-visible': filterState }">
    </div>
  </Teleport>

  <ServiceRequestModal :settings="settings" :item="selectedItem" v-if="selectedItem" />
</template>

<script>
import CatalogItemPreview from '@/views/CatalogItemPreview';
import ServiceRequestModal from '@/components/ServiceRequestModal';

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export default {
  name: 'Catalog',
  components: { CatalogItemPreview, ServiceRequestModal },
  props: ['category'],
  data() {
    return {
      optionSelect: [],
      items: [],
      selectedItem: undefined,
      filterState: false,
      load: false,
      lastLoadT: 0,
      page: 1,
      perPage: 12,
      total: 0,
      more: true,
      checkLoader: undefined,
      loadBefore: 0,
      loadTimeout: undefined
    }
  },

  beforeUnmount() {
    window.document.removeEventListener('scroll', this.onscroll);
  },

  updated() {
    if (this.more) {
      this.loadBefore = this.checkLoader.offsetTop;
    } else {
      let body = document.body, html = document.documentElement;
      this.loadBefore = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) + 1000;
    }
  },

  mounted() {
    this.checkLoader = this.$refs.loadMore;
    window.document.addEventListener('scroll', this.onscroll);

    this.getItems();
    this.setSeo(this.categoryObject);

    this.$ViewCategory(this.categoryObject)
  },
  methods: {
    toggleFilter() {
      this.filterState = !this.filterState
    },
    onscroll(e) {
      if (document.documentElement.scrollTop + window.innerHeight > this.loadBefore && this.more) {
        this.page += 1;
        this.getItems();
      }
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },

    loadLater(clear) {
      console.log('load later...')
      if (this.loadTimeout) {
        clearTimeout(this.loadTimeout)
      }
      this.loadTimeout = setTimeout(() => {
        this.load = false
        this.getItems(clear)
      }, 100)
    },
    async getItems(clear) {
      if (this.settings.symbol === undefined) {
        this.loadLater(clear);
        return;
      }
      // if (this.load) {
      //   return;
      // }
      // this.load = true;
      if (clear) {
        this.page = 1;
        this.more = true;
      }
      let filter = { page: this.page, perPage: this.perPage };
      if (this.searchBy.length) {
        filter.category = this.searchBy
      } else if (this.settings.menu_type === 'advanced') {
        this.loadLater(clear)
        return;
      } else if (this.categoryName) {
        filter.category = this.categoryObject.id
      } else {
        filter.url = this.category
      }

      // if (category){
      //   filter.category = category
      // }

      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.$store.getters.getTags.find(o => o.id === id)).map(x => x ? x.id : x);
      } else if (filter.tags) {
        delete filter.tags
      }
      console.log(this.optionSelect, filter)

      let loadT = new Date().getTime();
      fetch('/public/item/filter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ filter: filter, sortBy: 'sort' })
      }).then(response => response.json()).then(data => {
        this.load = false;
        if (loadT < this.lastLoadT) {
          return
        }
        this.lastLoadT = loadT;
        if (clear) {
          this.items = data.result;
        } else {
          for (let i = 0; i < data.result.length; i++) {
            if (this.items.findIndex(x => x.id === data.result[i].id) === -1) {
              this.items.push(data.result[i]);
            }
          }
        }
        this.page = data.page;
        this.total = data.total;
        if (this.items.length === this.total) {
          this.more = false;
        }
      })
    },

    handleLeaveLeadBtnClick(item) {
      this.selectedItem = item;
    },

    setSeo(val) {
      if (val && val.info) {
        let title = val.info.title ? val.info.title : this.$dynamic(this.categoryObject, this.categoryName, 'name');

        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);

        let description = val.info.description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');
        let image = val.image ? `${window.location.protocol}//${window.location.host}${val.image}` : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      }
    }
  },

  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    categoryGroups() {
      return this.categoryObject?.info?.groups
    },
    groups() {
      return this.categoryGroups ?
        this.categoryGroups.map(group => {
          let list = group.list.map(id => this.storeTags.find(i => i.id === id)).filter(i => i && i.id)
          list = list.sort((a, b) => {
            if (isNumeric(a.name) && isNumeric(b.name)) {
              return parseFloat(a.name) - parseFloat(b.name)
            }

            return b.name.localeCompare(a.name)
          })
          return Object.assign({}, group, { list: list })
        }) : this.categoryGroups
    },
    searchBy() {
      return this.$store.getters.getSearchBy
    },
    categoryTags() {
      return this.categoryObject?.info?.tags
    },
    tags() {
      return this.categoryTags ?
        this.categoryTags.map(id => this.storeTags.find(i => i.id === id)).filter(i => i && i.id).sort((a, b) => a.order - b.order)
        : []
    },
    short_tags() {
      return this.tags.slice(0, this.settings?.labelQuantity)
    },
    storeTags() {
      return this.$store.getters.getTags
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    },
    categoryObject() {
      return this.$store.getters.getCategories.find(i => i.info.url === this.category)
    },
    categoryName() {
      return this.categoryObject ? this.categoryObject.name : ''
    },
    categoryImage() {
      return this.categoryObject && this.categoryObject.image ? this.categoryObject.image : ''
    }
  },
  watch: {
    category(newVal) {
      this.optionSelect = [];
      if (this.searchBy?.length) {
        return
      }
      this.$ViewCategory(this.categoryObject)

      this.getItems(true)
    },
    searchBy() {
      this.$ViewCategory(this.categoryObject)
      this.getItems(true)
    },
    optionSelect(val) {
      this.getItems(true)
    },
    categoryObject(val) {
      this.setSeo(val)
    }
  }
}
</script>
