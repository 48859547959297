<template>
  <div class="order-wrapper wrapper">
    <div class="name h2">{{ $static('order.title', 'Оформлення замовлення:') }}</div>

    <!--! Удаленные товары-->
    <div class="order-info deleted panel" v-if="removedItemsBusked.length">
      <h3>Видалені товари</h3>
      <div class="cart-item ">
        <template v-for="(item, index) in removedItemsBusked" :key="index">
          <div class="item-holder">
            <div class="item-info">
              <div class="image bg-cover" :style="{ 'background-image': $filters.getItemImage(item.preview) }">

              </div>
              <div class="right-side">
                <!--              <div class="name">{{item.title}}</div>-->
                <router-link :to="{ name: 'ItemOrServiceItem', params: { category: categoryURL(item), id: item.id } }">
                  {{ item.title }}
                </router-link>
                <div class="info-labels">
                  <span class="info-label">
                    {{ $static('order.count', 'Кількість:') }} <span>{{ item.count }}{{
                      $static('busked.nam', 'шт')
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="item-options">
              <div class="product-price">
                <div class="additional-line" v-if="item.price">
                  <div class="add-name">{{ $static('order.price', 'Ціна') }}</div>
                  <!-- ? Сделать стили -->
                  <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                    {{ $store.getters.getSettings.symbol }}
                  </div>
                  <div class="price-element" :class="{ 'was-selled': !!item.price_old }"> {{
                    $filters.money(item.price)
                  }} {{ $store.getters.getSettings.symbol }}</div>
                  <!-- ? ------------------------ -->
                </div>
              </div>
              <div class="additional" v-if="item.additionSelect">
                <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                  <div class="add-name">{{ m.name }}</div>
                  <p class="value" v-if="m.price">{{ m.price }} {{ $store.getters.getSettings.symbol }}</p>
                </div>
              </div>
              <div class="additional" v-if="item.additionUSelect">
                <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                  <div class="add-name">{{ m.name }}</div>
                  <p class="value" v-if="m.price">{{ m.price }} {{ m.old_price }}
                    {{ $store.getters.getSettings.symbol }}</p>
                </div>
              </div>

              <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }}
                {{ $store.getters.getSettings.symbol }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!--! Недоступные товары-->
    <div class="order-info unavailable panel" v-if="unavailableItemsBusked.length">
      <h3>Недоступні товари</h3>
      <div class="cart-item ">
        <template v-for="(item, index) in unavailableItemsBusked" :key="index">
          <div class="item-holder">
            <div class="item-info">
              <div class="image bg-cover" :style="{ 'background-image': $filters.getItemImage(item.preview) }">

              </div>
              <div class="right-side">
                <!--              <div class="name">{{item.title}}</div>-->
                <router-link :to="{ name: 'ItemOrServiceItem', params: { category: categoryURL(item), id: item.id } }">
                  {{ item.title }}
                </router-link>
                <div class="info-labels">
                  <span class="info-label">
                    {{ $static('order.count', 'Кількість:') }} <span>{{ item.count }}{{
                      $static('busked.nam', 'шт')
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="item-options">
              <div class="product-price">
                <div class="additional-line" v-if="item.price">
                  <div class="add-name">{{ $static('order.price', 'Ціна') }}</div>
                  <!-- ? Сделать стили -->
                  <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                    {{ $store.getters.getSettings.symbol }}
                  </div>
                  <div class="price-element" :class="{ 'was-selled': !!item.price_old }"> {{
                    $filters.money(item.price)
                  }} {{ $store.getters.getSettings.symbol }}</div>
                  <!-- ? ------------------------ -->
                </div>
              </div>
              <div class="additional" v-if="item.additionSelect">
                <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                  <div class="add-name">{{ m.name }}</div>
                  <p class="value" v-if="m.price">{{ m.price }} {{ $store.getters.getSettings.symbol }}</p>
                </div>
              </div>
              <div class="additional" v-if="item.additionUSelect">
                <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                  <div class="add-name">{{ m.name }}</div>
                  <p class="value" v-if="m.price">{{ m.price }} {{ m.old_price }}
                    {{ $store.getters.getSettings.symbol }}</p>
                </div>
              </div>

              <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }}
                {{ $store.getters.getSettings.symbol }}
              </div>
            </div>
          </div>
          <label>
            <input type="checkbox" name="" id="" v-model="availabilityNotify[index]">
            <span>Не залишати заявку на цей товар</span>
          </label>

        </template>
      </div>
    </div>

    <!--! Доступные товары-->
    <div class="order-info panel" v-if="availableItemsBusked.length">
      <h3>{{ $static('order.info', 'Ваше замовлення') }}</h3>
      <div class="cart-item ">
        <div class="item-holder" v-for="(item, index) in availableItemsBusked" :key="index">
          <div class="item-info">
            <div class="image bg-cover" :style="{ 'background-image': $filters.getItemImage(item.preview) }">

            </div>
            <div class="right-side">
              <!--              <div class="name">{{item.title}}</div>-->
              <router-link :to="{ name: 'ItemOrServiceItem', params: { category: categoryURL(item), id: item.id } }">
                {{ item.title }}
              </router-link>
              <div class="info-labels">
                <span class="info-label">
                  {{ $static('order.count', 'Кількість:') }} <span>{{ item.count }}{{
                    $static('busked.nam', 'шт')
                  }}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="item-options">
            <div class="product-price">
              <div class="additional-line" v-if="item.price">
                <div class="add-name">{{ $static('order.price', 'Ціна') }}</div>
                <!-- ? Сделать стили -->
                <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                  {{ $store.getters.getSettings.symbol }}
                </div>
                <div class="price-element" :class="{ 'was-selled': !!item.price_old }"> {{
                  $filters.money(item.price)
                }} {{ $store.getters.getSettings.symbol }}</div>
                <!-- ? ------------------------ -->
              </div>
            </div>
            <div class="additional" v-if="item.additionSelect">
              <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                <div class="add-name">{{ m.name }}</div>
                <p class="value" v-if="m.price">{{ m.price }} {{ $store.getters.getSettings.symbol }}</p>
              </div>
            </div>
            <div class="additional" v-if="item.additionUSelect">
              <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                <div class="add-name">{{ m.name }}</div>
                <p class="value" v-if="m.price">{{ m.price }} {{ m.old_price }}
                  {{ $store.getters.getSettings.symbol }}</p>
              </div>
            </div>

            <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }}
              {{ $store.getters.getSettings.symbol }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ! Персональные данные -->
    <div class="personal-data">
      <h3>{{ $static('order.b1', '1. Персональні дані') }}</h3>
      <div class="panel">

        <div class="form-group" :class="{ 'error': errors['name'] }" v-if="buskedSettings.name">
          <label class="label-custom">
            {{ $static('order.name', 'Ім\'я') }}<span v-if="buskedSettings.name_require">*</span>
          </label>
          <input type="text" @focus="delError('name')" class="form-control custom-input"
            :placeholder="$static('order.enter-name', 'Введіть ваше ім\'я')" v-model="order.name" name="name">
          <p class="help-text" v-for="(err, key) in errors['name']" :key="key">{{ err }}</p>
        </div>

        <div class="form-group" :class="{ 'error': errors['surname'] }" v-if="buskedSettings.sname">
          <label class="label-custom">
            {{ $static('order.surname', 'Прізвище') }}<span v-if="buskedSettings.sname_require">*</span>
          </label>
          <input type="text" @focus="delError('surname')" class="form-control custom-input"
            :placeholder="$static('order.enter-surname', 'Введіть ваше прізвище')" v-model="order.surname"
            name="second_name">
          <p class="help-text" v-for="(err, key) in errors['surname']" :key="key">{{ err }}</p>
        </div>

        <div class="form-group phone-input order-phone-input" :class="{ 'error': errors['phone'] }"
          v-if="buskedSettings.phone || !isWebApp">
          <label class="label-custom">
            {{ $static('order.phone', 'Телефон') }}<span v-if="buskedSettings.phone_require">*</span>
          </label>


          <input type="tel" id="order-tel" class="form-control custom-input" v-model="order.phone"
            @focus="delError('phone')">
          <p class="help-text" v-for="(err, key) in errors['phone']" :key="key">{{ err }}</p>
        </div>

        <div class="form-group" :class="{ 'error': errors['email'] }" v-if="buskedSettings.email">
          <label class="label-custom">
            {{ $static('order.email', 'Email') }}<span v-if="buskedSettings.email_require">*</span>
          </label>
          <input type="text" @focus="delError('email')" class="form-control custom-input"
            :placeholder="$static('order.enter-email', 'Введіть ваш email')" v-model="order.email" name="email">
          <p class="help-text" v-for="(err, key) in errors['email']" :key="key">{{ err }}</p>
        </div>
      </div>
    </div>
    <!--! --------------------------- -->

    <!-- ! Раздел оплаты -->
    <div class="payment-data">
      <h3>{{ $static('order.b3', '2. Оплата') }}</h3>
      <div class="option-group">
        <div class="empty-text" v-if="payment.length === 0">
          <p>{{ $static('order.empty-pay', 'У вас немає активованих способів оплати') }}</p>
        </div>
        <div class="option-item" v-for="(pay, index) in payment" :key="index" @click="order.pay_type = pay.id"
          :class="{ 'active': order.pay_type === pay.id && !(!isTelegram && !userInfo && pay.manifest.pay_type === 'telegram'), 'not-allowed': !isTelegram && !userInfo && pay.manifest.pay_type === 'telegram' }">
          <div class='option-item-body'>
            <p>{{ $dynamic(pay.manifest, pay.manifest.title, 'title') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="bonus-panel" v-if="maxBonus > 0">
      <div class="form-group">
        <label class="label-custom">
          {{ $static('order.bonus-payment', 'Розрахуватись бонусами:') }}
          <span v-if="settings?.max_bonus && settings?.max_bonus !== 100">
            {{ $static('order.bonus-no-more', 'не більше ніж ') }}
            {{ settings?.max_bonus }}%
            {{ $static('order.bonus-procent', 'від суми') }}</span>
        </label>
        <div class="input-btn-group">
          <button class="default-btn" @click="setBonusMax">
            <span class="btn-text">Max {{ maxBonus }}</span>
          </button>
          <input type="text" class="form-control custom-input" :placeholder="0" v-model.number="order.bonus_discount">
        </div>
      </div>
      <!--      <div class="d-none">-->
      <!--        <label class="label-custom">-->
      <!--          {{ $static('order.bonus-payment', 'Розрахуватись бонусами:') }} <span>{{-->
      <!--            $static('order.must-login', 'Необхідна авторизація')-->
      <!--          }}</span>-->
      <!--        </label>-->
      <!--      </div>-->
    </div>

    <!-- TODO .................................. -->
    <OrderDelivery :delivery="delivery" :errors="errors" @errorCleaningFocus="delError" />
    <!-- TODO .................................. -->

    <div class="comment-data form-group" :class="{ 'error': errors['comment'] }" v-if="buskedSettings.comment">
      <label class="label-custom">
        {{ $static('order.b4', '4. Коментар') }}<span v-if="buskedSettings.comment_require">*</span>
      </label>
      <div class="comment-group	form-group">
        <textarea @focus="delError('comment')" class="form-control custom-input"
          :placeholder="$static('comment-text', 'Введіть текст коментаря')" v-model="order.comment"></textarea>
        <p class="help-text" v-for="(err, key) in errors['comment']" :key="key">{{ err }}</p>
      </div>
    </div>

    <div class="order-total panel" v-if="min_price && total < min_price" :class="{ 'error': errors.min_price }">
      <div class="name">{{ $static('order.min_price', 'Мінімальна сумма замовлення') }}</div>
      <div class="value">
        <div class="price-element">{{ $filters.money(min_price) }} {{ $store.getters.getSettings.symbol }}</div>
      </div>
    </div>

    <div class="order-total panel" v-else-if="availableItemsBusked.length">
      <div class="name">{{ $static('order.total', 'Всього до сплати') }}:</div>
      <div class="value">
        <div class="price-element">{{ $filters.money(total) }} {{ $store.getters.getSettings.symbol }}</div>
      </div>
    </div>

    <div class="order-buttons"
      :class="{ 'disabled': (min_price && total < min_price && !onlyLead) || (!onlyLead && availableItemsBusked.length === 0) || (!isTelegram && !userInfo && isTelegramPayActive) }">
      <button class="default-btn" v-if="submiting">
        <span class="btn-text">{{ $static('order.submiting', 'Відправка...') }}</span>
      </button>
      <button class="default-btn" v-else @click.prevent.stop="makeOrder()"
        :disabled='(!min_price && !onlyLead && availableItemsBusked.length === 0)'>
        <!-- :disabled='(!min_price && !onlyLead && availableItemsBusked.length === 0) || (!isTelegram && !userInfo && isTelegramPayActive)' -->

        <span class="btn-text" v-if="onlyLead">{{ $static('order.onlyLead', 'Залишити заявку') }}</span>
        <span class="btn-text" v-else-if="isDeliveryCustom">{{ $static('order.makeOrder', 'Замовити') }}</span>
        <span class="btn-text" v-else>{{ $static('order.pay', 'Сплатити') }}</span>
      </button>


      <div class="link-holder">
        <router-link :to="{ name: 'home' }" class="back-link">
          <img src="images/icons/left.svg" alt="">
          <span class="text">{{ $static('order.back', 'Назад у магазин') }}</span>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import OrderDelivery from '../components/OrderDelivery';
import intlTelInput from 'intl-tel-input';

export default {
  name: 'Order',
  components: { OrderDelivery },
  data() {
    return {
      checkedBusked: [],
      availabilityNotify: [],
      submiting: false,
      errors: {},
      delivery: [],
      payment: [],
      order: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        pay_type: undefined,
        comment: '',
        bonus_discount: 0
      },
      iti: undefined
    }
  },
  async mounted() {
    this.$OrderPage();
    document.title = 'Order';
    fetch('/public/plugin').then(response => response.json()).then(data => {
      let defaultPayment = undefined;
      let defaultDelivery = undefined;

      data.forEach(x => {
        this[x.type] = x.list;
        if (x.list.length) {
          let defaultValue = x.list[0];
          switch (x.type) {
            case 'payment':
              defaultPayment = defaultValue.id;
              break;
            case 'delivery':
              defaultDelivery = defaultValue;
              break
          }
        }
      });
      if (defaultDelivery) {
        this.$store.commit('setDeliveryInfo', defaultDelivery);
      }
      if (defaultPayment) {
        this.order.pay_type = defaultPayment
      }
    });

    if (this.deliveryOptions.length) {
      this.delivery.type = this.deliveryOptions[0]
    }
    // if (this.payment.length) {
    //   let payDefault = this.payment.find(i => i.settings.default);
    //   if (payDefault) {
    //     this.order.pay_type = payDefault
    //   } else {
    //     this.order.pay_type = this.payment[0]
    //   }
    // }
    await this.checkBuskedItems();
    this.unavailableItemsBusked.forEach((item, itemIndex) => { this.availabilityNotify[itemIndex] = false });

    const telInput = document.querySelector("#order-tel");
    if (telInput) {
      this.iti = intlTelInput(telInput, {
        utilsScript: "/js/utils.js",
        customContainer: "phone-input",
        initialCountry: "auto",
        countryOrder: ['ua', 'pl'],
        allowDropdown: true,
        autoPlaceholder: "polite",
        formatOnDisplay: true,
        nationalMode: false,
        placeholderNumberType: "MOBILE",
        showFlags: true,
        separateDialCode: true,
        strictMode: true,
        validationNumberType: "MOBILE",
        geoIpLookup: callback => {
          fetch("https://ipapi.co/json")
            .then(res => res.json())
            .then(data => callback(data.country_code))
            .catch(() => callback("ua"));
        }
      })
    }
  },
  computed: {
    isTelegram() {
      if (window.Telegram && window.Telegram?.WebApp && window.Telegram.WebApp.initData && window?.Telegram?.WebApp?.initData.length > 0) { return true }
      else { return false }
    },
    isTelegramPayActive() {
      // Это свойство нужно для определения выбраного способа оплаты. Нам важен телеграм пэй, потому что 
      //в вебверсии при незалогиненом юзере мы блокируем возможность сделать заказ
      if (this.order.pay_type && Array.isArray(this.payment) && this.payment.length > 0) {
        const paymentObj = this.payment.find(item => item.id === this.order.pay_type);
        let paymentName = null;
        if (paymentObj) {
          paymentName = paymentObj.manifest.pay_type;
        }
        if (paymentName === "telegram") {
          return true
        } else {
          return false
        }
      }

      return false
    },
    deliveryInfo() {
      return this.$store.getters.getDeliveryInfo;
    },
    deliveryOptions() {
      return this.$store.getters.getDeliveryOptions
    },
    userInfo() {
      return this.$store.getters.getUserInfo
    },
    account() {
      return this.$store.getters.getAccount
    },
    bonus() {
      return this.account ? this.account.bonus : 0
    },
    maxDiscountByPrice() {
      return this.total * (this.settings?.max_bonus ? (Math.min(Math.max(this.settings.max_bonus, 0), 100) / 100) : 0)
    },
    maxBonus() {
      return this.bonus ? Math.floor(Math.min(this.maxDiscountByPrice, this.bonus)) : 0
    },
    isWebApp() {
      return this.$store.getters.isWebApp
    },

    isDeliveryCustom() {
      if (this.order && this.order.pay_type) {
        let d = this.payment.find(x => x.id === this.order.pay_type)
        if (d && d.name === 'custom') {
          return true
        }
      }
      return false
    },
    buskedSettings() {
      return this.settings && this.settings.busked ? this.settings.busked : {}
    },
    min_price() {
      return this.buskedSettings.min_price
    },
    anonymous() {
      return this.buskedSettings.anonymous
    },
    deliveryDisable() {
      return this.buskedSettings.delivery
    },
    commentDisable() {
      return this.buskedSettings.comment
    },
    busked() {
      return this.$store.getters.getBusked
    },
    removedItemsBusked() {
      return this.busked.filter((item) => !this.checkedBusked.some(checkedItem => {
        if (checkedItem.id === item.id && checkedItem.hidden) { return false }
        return checkedItem.id === item.id
      }))
    },
    unavailableItemsBusked() {
      return this.busked.filter(buskedItem =>
        this.checkedBusked.some(checkedItem => checkedItem.id === buskedItem.id && (!checkedItem.stock || checkedItem?.advanced.isService) && !checkedItem.hidden)
      );
    },
    availableItemsBusked() {
      return this.busked.filter(buskedItem =>
        this.checkedBusked.some(checkedItem => checkedItem.id === buskedItem.id && checkedItem.stock && !checkedItem?.advanced.isService && !checkedItem.hidden)
      );
    },
    total() {
      return this.availableItemsBusked.reduce((c, item) => c + item.count * this.itemPrice(item), 0)
    },
    settings() {
      return this.$store.getters.getSettings
    },
    BOT_NAME() {
      return this.settings.BOT_NAME ? this.settings.BOT_NAME : false
    },
    onlyLead() {
      const areLeadsTrue = this.availabilityNotify.some(boollean => !boollean)
      if (areLeadsTrue && this.availableItemsBusked.length === 0) {
        return true;
      }
      return false;
    }
  },
  methods: {
    async checkBuskedItems() {
      const ids = this.busked.map(item => item.id);

      try {
        const response = await fetch('/public/item/filter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: {
              ids: ids
            }
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        this.checkedBusked = data.result;

        // Получаем ID доступных элементов
        // const availableIds = data.result
        //   .filter(item => item?.stock)
        //   .map(item => item.id);

        // Фильтруем исходный массив busked, чтобы оставить только те элементы, ID которых есть в availableIds
        // this.checkedBusked = this.busked.filter(item => availableIds.includes(item.id));

      } catch (error) {
        console.error('Error of basket items check:', error);
      }
    },
    setBonusMax() {
      this.order.bonus_discount = this.maxBonus
    },
    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    telegramLoadedCallbackFunc() {
      // console.log('script is loaded')
      this.isLoaded = true
    },
    yourCallbackFunction(user) {
      // console.log(user);
      localStorage.setItem('user_info', JSON.stringify(user));
      this.makeOrder(user);
      // fetch('/auth/login', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(user)
      // })
      //   .then(response => response.json())
      //   .then(json => {
      //     localStorage.setItem('user_info', JSON.stringify(user));
      //     window.user_info = user;
      //     if (json.error === undefined) {
      //       if (json.init) {
      //         this.$router.push({name: 'Init'})
      //       } else {
      //         this.$router.push({name: 'Dashboard'})
      //       }
      //     }
      //   })
    },

    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
    addition_price(item) {
      return item.additionSelect ? item.additionSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    addition_Uprice(item) {
      return item.additionUSelect ? item.additionUSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
    },
    showError(title, str) {
      window.$('#error').addClass('is-visible')
      window.$('#error .h2').html(title)
      window.$('#error p').html(str)
    },
    itemPrice(item) {
      return item.price + this.addition_price(item) + this.addition_Uprice(item)
    },
    async sendLeads(orderId, orderComment = undefined) {
      if (this.submiting) {
        return;
      }

      this.submiting = true;

      const itemArrray = [];

      try {
        for (const [leadItemIndex, leadNecessity] of this.availabilityNotify.entries()) {
          if (!leadNecessity) {
            itemArrray.push(this.unavailableItemsBusked[leadItemIndex]);
          }
        }
        const busked = itemArrray.map(i => ({
          id: i.id,
          count: i.count,
          add: i.additionSelect.map(i => i.id),
          u_select: i.additionUSelect
        }));

        const order = {
          busked: busked,
          name: this.order.name,
          email: this.order.email,
          phone: this.iti.getNumber(),
          user: this.userInfo || undefined,
          orderId: orderId,
          orderPageComment: orderComment
        };

        const headers = {};
        const initCache = localStorage.getItem('initData');
        if (initCache) {
          headers.initData = initCache;
        }

        try {
          const resp = await fetch('/public/lead', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(order),
            headers: headers
          });

          const data = await resp.json();

          if (data.error) {
            throw new Error(data.error.message)
          }

          // Закрытие модального окна, если требуется
          (function ($) {
            'use strict';
            $('.close').click();
          })(window.$);
          return data;
        } catch (err) {
          console.error('Fetch error:', err);
          this.showError('Сталася помилка', 'Не вдалося відправити заявку на товар. Перевірте інтернет з\'єднання та спробуйте ще раз');
        }

      } catch (err) {
        console.error('Unexpected error:', err);
        this.showError('', 'Сталася непередбачувана помилка. Спробуйте пізніше');
      } finally {
        this.submiting = false;

      }
    },



    async makeOrder(user) {
      if (this.submiting) {
        return
      }

      let valid = this.validate();
      if (!valid) {
        if (window.scrollTarget) {
          window.scrollTo({
            top: window.scrollTarget.offsetTop,
            behavior: 'smooth'
          });
          window.scrollTarget = undefined
        } else {
          window.scrollTo(0, 0);
        }
        return
      }

      let order = Object.assign({}, this.order, {
        delivery: this.$store.getters.getDeliveryInfo,
        busked: this.availableItemsBusked.map(i => {
          return {
            id: i.id,
            count: i.count,
            add: i.additionSelect?.map(i => i.id),
            u_select: i.additionUSelect
          }
        })
      });

      let delivery = Object.assign({ meta: {} }, order.delivery)
      try {
        if (delivery.type?.name) {
          delivery.type = delivery.type.name
        }

        if (delivery.warehouse?.Description) {
          delivery.meta = Object.assign({}, delivery.meta, { np_warehouse: delivery.warehouse })
          delivery.warehouse = delivery.warehouse.Description
        }

        if (delivery.address?.Description) {
          delivery.meta = Object.assign({}, delivery.meta, { np_address: delivery.address })
          delivery.address = delivery.address.Description
        }

        if (delivery.city?.Description) {
          delivery.meta = Object.assign({}, delivery.meta, { np_city: delivery.city })
          delivery.city = delivery.city.Description
        }

        if (order?.pay_type?.name) {
          order.pay_type = order.pay_type.name
        }
      } catch (err) {
        console.error('Unexpected error:', err);
        this.showError('', 'Сталася непередбачувана помилка. Спробуйте пізніше');
        return;
      }
      order.delivery = delivery
      // if (this.deliveryDisable) {
      //   order.delivery = undefined
      // }

      let headers = {};
      let initCache = localStorage.getItem('initData');
      try {
        if (user) {
          order.user = user;
        }
        if (this.userInfo) {
          order.user = this.userInfo;
        }
        if (initCache && initCache.length) {
          headers.initData = initCache;
        }

        this.$Purchase(this.availableItemsBusked);
      } catch (err) {
        console.error('Unexpected error:', err);
        this.showError('', 'Сталася непередбачувана помилка. Спробуйте пізніше');
        return;
      }

      if (order.user === undefined || order.user === null) {
        delete order.user;
      }

      let phone = this.order.phone;
      if (phone.startsWith("0")) {
        phone = phone.slice(1);
      }



      try {
        //Если есть недоступные товары, но корзина с доступными пуста то шлем заявку и уходим в историю заявок
        if (this.onlyLead) {

          const leadReqData = await this.sendLeads(undefined, this.order.comment);


          this.submiting = false;

          if (!leadReqData) {
            window.$('#error').addClass('is-visible');
            return;
          }

          window.$('#thx-lead').addClass('is-visible');

          if (initCache) {
            setTimeout(() => {
              window.Telegram.WebApp.openTelegramLink(`https://t.me/${this.settings.BOT_NAME}`)
              window.Telegram.WebApp.close();
            }, 1000);
          }

          this.$store.commit('clearBasked');

          setTimeout(() => {
            this.$router.push({ name: 'CabinetLeadHistory' });
            window.$('#thx-lead').removeClass('is-visible');
          }, 1800);
          return
        }
        //_______________________________

        this.submiting = true;

        const response = await fetch('/public/order', {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(Object.assign({}, order, { phone: this.iti.getNumber() })),
          headers: headers
        });

        const data = await response.json();
        this.submiting = false;

        if (data.error) {
          console.error('Unexpected error:', data.error);
          this.showError('', 'Сталася непередбачувана помилка. Спробуйте пізніше');
          return;
        }

        window.$('#thx').addClass('is-visible');

        if (this.unavailableItemsBusked.length > 0) {
          await this.sendLeads(data.id);
        }

        if (initCache) {
          setTimeout(() => {
            window.Telegram.WebApp.openTelegramLink(`https://t.me/${this.settings.BOT_NAME}`)
            window.Telegram.WebApp.close();
          }, 1000);
        }
        this.$store.commit('clearBasked');

        if (data.link) {
          setTimeout(() => {
            window.location.href = data.link;
          }, 1500);
        } else {
          setTimeout(() => {
            window.$('#thx').removeClass('is-visible');
            this.$router.push({ name: 'CabinetHistory' });
          }, 3000);
        }

      } catch (err) {
        this.submiting = false;
        console.error('Unexpected error:', err);
        this.showError('', 'Сталася непередбачувана помилка. Спробуйте пізніше');
        window.$('#thx').removeClass('is-visible');
        window.$('#errorModal').removeClass('is-visible');
      }


    },
    delError(key) {
      delete this.errors[key]
    },
    validate() {
      this.errors = {};

      // Проверяем доступна ли возможность сделать заказ
      //1. Если в корзине не выбраны товары для заявки и отсутсвуют товары достцупные для заказа, то действия по оформлению заявки или заказа осуществить нельзя
      if (!this.min_price && !this.onlyLead && this.availableItemsBusked.length === 0) {
        console.error("Відсутні доступні до замовлення товари та/або не обрані товари для оформлення заявки");
        this.showError('Сталася помилка', 'Відсутні доступні до замовлення товари та/або не обрані товари для оформлення заявки');
        setTimeout(() => {
          window.$('#error').removeClass('is-visible');
        }, 5000);
        return false
      }
      //2. Если юзер не залогинен, и он зашел не с телеграма, а выбран хоть и заблокированный способ оплаты TelegramPay, то выдаем ошибку
      if (!this.isTelegram && !this.userInfo && this.isTelegramPayActive && !this.onlyLead) {
        this.showError('Сталася помилка', 'Для можливості зробити замовлення з обраним типом оплати необхідно авторизуватися')

        setTimeout(() => {
          window.$('#error').removeClass('is-visible');
        }, 5000);
        return false
      }

      if ((this.min_price && this.total < this.min_price) &&
        !this.onlyLead) {
        this.showError('', this.$static('order.min_price', 'Сума замовлення нижче мінімальної') +
          `: ${this.$filters.money(this.min_price)} ${this.$store.getters.getSettings.symbol}`)
        window.scrollTarget = document.querySelector('.order-total')
        this.errors.min_price = true;
        return false
      }
      window.scrollTarget = document.querySelector('.personal-data')

      if (this.buskedSettings.name && this.buskedSettings.name_require) {
        if (this.order.name.length === 0) {
          this.errors.name = [this.$static('order.name-require-error', 'Ім\'я обов\'язкове')];
          this.showError('', this.$static('order.name-require-error', 'Ім\'я обов\'язкове'))
          return false
        }
        if (this.order.name.length < 2) {
          this.errors.name = ['Ім\'я повинно містити не менше 3 символів'];
          this.showError('', 'Ім\'я повинно містити не менше 3 символів')
          return false
        }
      }

      if (this.buskedSettings.sname && this.buskedSettings.sname_require) {
        if (this.order.surname.length === 0) {
          this.errors.surname = ['Прізвище обов\'язкове'];
          this.showError('', 'Прізвище обов\'язкове')
          return false
        }
        if (this.order.surname.length < 2) {
          this.errors.surname = ['Прізвище повинно містити не менше 3 символів'];
          this.showError('', 'Прізвище повинно містити не менше 3 символів')
          return false
        }
      }

      //ToDo: check is telegram exist - if not then phone require
      if ((this.buskedSettings.phone && this.buskedSettings.phone_require || this.account === undefined)) {
        if (this.order.phone.length === 0) {
          this.errors.phone = ['Номер телефону обов\'язковий'];
          this.showError('', 'Номер телефону обов\'язковий')
          return false
        }
        if (!this.iti.isValidNumber()) {
          this.errors.phone = [this.$static('order.phone-error', 'Введіть коректний номер')];
          this.showError('', this.$static('order.phone-error', 'Введіть коректний номер'))
          return false
        }
      }

      if (this.buskedSettings.email && this.buskedSettings.email_require) {
        const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (this.order.email.length === 0) {
          this.errors.email = ['Email обов\'язковий'];
          this.showError('', 'Email обов\'язковий')
          return false
        }
        if (!emailRegExp.test(this.order.email)) {
          this.errors.email = ['Введiть коректний email '];
          this.showError('', 'Введiть коректний email ')
          return false
        }
      }

      // if (!this.buskedSettings.delivery) {
      window.scrollTarget = document.querySelector('.delivery-data')

      // Валидация полей Курьерской доставки
      if (this.deliveryInfo.name === 'custom') {
        if (this.deliveryInfo.manifest.city && (this.deliveryInfo.city === undefined || this.deliveryInfo.city.length === 0)) {
          this.errors.delivery_city = [this.$static('delivery-city-error', 'Місто обов\'язкове')];
          this.showError('', this.$static('delivery-city-error', 'Місто обов\'язкове'))
          return false
        }

        if (this.deliveryInfo.manifest.address && (this.deliveryInfo.address === undefined || this.deliveryInfo.address.length === 0)) {
          this.errors.delivery_address = [this.$static('delivery-address-error', 'Адреса обов\'язкова')];
          this.showError('', this.$static('delivery-address-error', 'Адреса обов\'язкова'))
          return false
        }

        if (this.deliveryInfo.manifest.zip && (this.deliveryInfo.zip === undefined || this.deliveryInfo.zip.length === 0)) {
          this.errors['delivery_zip'] = ['Iндекс обов\'язковий'];
          this.showError('', 'Iндекс обов\'язковий')
          return false
        }

        if (this.deliveryInfo.manifest.warehouse && (this.deliveryInfo.warehouse === undefined || this.deliveryInfo.warehouse.length === 0)) {
          this.errors.delivery_warehouse = [this.$static('delivery-warehouse-error', 'Відділення обов\'язкове')];
          this.showError('', this.$static('delivery-warehouse-error', 'Відділення обов\'язкове'))
          return false
        }

        if (this.deliveryInfo.manifest.floor && (this.deliveryInfo.floor === undefined || this.deliveryInfo.floor.length === 0)) {
          this.errors['delivery_floor'] = ['Поверх обов\'язковий'];
          this.showError('', this.$static('delivery_floor', 'Поверх обов\'язковий'))
          return false
        }

        if (this.deliveryInfo.manifest.apartment && (this.deliveryInfo.apartment === undefined || this.deliveryInfo.apartment.length === 0)) {
          this.errors['delivery_apartment'] = ['Номер квартири обов\'язковий'];
          this.showError('', this.$static('delivery_apartment', 'Номер квартири обов\'язковий'))
          return false
        }
      }

      // Валидация полей Укрпочты
      if (this.deliveryInfo.name === 'Urk Poshta') {
        if (this.deliveryInfo.city === undefined || this.deliveryInfo.city.length === 0) {
          this.errors.delivery_city = [this.$static('delivery-city-error', 'Місто обов\'язкове')];
          this.showError('', this.$static('delivery-city-error', 'Місто обов\'язкове'))
          return false
        }

        if (this.deliveryInfo.warehouse === undefined || this.deliveryInfo.warehouse.length === 0) {
          this.errors.delivery_warehouse = [this.$static('delivery-warehouse-error', 'Відділення обов\'язкове')];
          this.showError('', this.$static('delivery-warehouse-error', 'Відділення обов\'язкове'))
          return false
        }
      }

      // Валидация полей Новой почты
      if (this.deliveryInfo.name === 'Nova Poshta') {
        if (this.deliveryInfo.city === undefined) {
          this.errors.delivery_city = [this.$static('delivery-city-error', 'Місто обов\'язкове')];
          this.showError('', this.$static('delivery-city-error', 'Місто обов\'язкове'))
          return false
        }
        if (this.deliveryInfo.city.Description === undefined) {
          this.errors.delivery_city = [this.$static('delivery-city-error2', 'Оберіть місто зі списку')];
          this.showError('', this.$static('delivery-city-error2', 'Оберіть місто зі списку'))
          return false
        }
        if (this.deliveryInfo.warehouse === undefined) {
          this.errors.delivery_warehouse = [this.$static('delivery-warehouse-error', 'Відділення обов\'язкове')];
          this.showError('', this.$static('delivery-warehouse-error', 'Відділення обов\'язкове'))
          return false
        }
        if (this.deliveryInfo.warehouse.Description === undefined) {
          this.errors.delivery_warehouse = [this.$static('delivery-warehouse-error2', 'Оберіть відділення зі списку')];
          this.showError('', this.$static('delivery-warehouse-error2', 'Оберіть відділення зі списку'))
          return false
        }
      }

      if (this.deliveryInfo.name === 'Nova Poshta Curier') {
        if (this.deliveryInfo.city === undefined) {
          this.errors.delivery_city = [this.$static('delivery-city-error', 'Місто обов\'язкове')];
          this.showError('', this.$static('delivery-city-error', 'Місто обов\'язкове'))
          return false
        }
        if (this.deliveryInfo.city.Description === undefined) {
          this.errors.delivery_city = [this.$static('delivery-city-error2', 'Оберіть місто зі списку')];
          this.showError('', this.$static('delivery-city-error2', 'Оберіть місто зі списку'))
          return false
        }
        if (this.deliveryInfo.address === undefined) {
          this.errors.delivery_address = [this.$static('delivery-address-error', 'Адреса обов\'язкова')];
          this.showError('', this.$static('delivery-address-error', 'Адреса обов\'язкова'))
          return false
        }

        if (this.deliveryInfo.b_number === undefined || this.deliveryInfo.b_number.length === 0) {
          this.errors.delivery_b_number = [this.$static('delivery-b-number', 'Номер будинку обов\'язковий')];
          this.showError('', this.$static('delivery-b-number', 'Номер будинку обов\'язковий'))
          return false
        }
        if (this.deliveryInfo.floor === undefined || this.deliveryInfo.floor.length === 0) {
          this.errors['delivery_floor'] = ['Поверх обов\'язковий'];
          this.showError('', this.$static('delivery_floor', 'Поверх обов\'язковий'))
          return false
        }

        if (this.deliveryInfo.apartment === undefined || this.deliveryInfo.apartment.length === 0) {
          this.errors['delivery_apartment'] = ['Номер квартири обов\'язковий'];
          this.showError('', this.$static('delivery_apartment', 'Номер квартири обов\'язковий'))
          return false
        }

      }
      // else {
      //   if (this.deliveryInfo.type && this.deliveryInfo.type.settings) {
      //     if (this.deliveryInfo.type.settings.city && (this.deliveryInfo.city === undefined || this.deliveryInfo.city.length < 3)) {
      //       this.errors.delivery_city = [this.$static('order.delivery-city-error', 'Місто обов\'язкове')];
      //       this.showError('', this.$static('order.delivery-city-error', 'Місто обов\'язкове'))
      //       return false
      //     }

      //     if (this.deliveryInfo.type.settings.address && (this.deliveryInfo.address === undefined || this.deliveryInfo.address.length < 3)) {
      //       this.errors.delivery_address = [this.$static('order.delivery-address-error', 'Адреса обов\'язкова')];
      //       this.showError('', this.$static('order.delivery-address-error', 'Адреса обов\'язкова'))
      //       return false
      //     }
      //     if (this.deliveryInfo.type.settings.warehouse && (this.deliveryInfo.warehouse === undefined || this.deliveryInfo.warehouse.length < 3)) {
      //       this.errors.delivery_warehouse = [this.$static('order.delivery-warehouse-error', 'Відділення обов\'язкове')];
      //       this.showError('', this.$static('order.delivery-warehouse-error', 'Відділення обов\'язкове'))
      //       return false
      //     }
      //   }
      // }

      // Валидация комментария
      if (this.buskedSettings.comment && this.buskedSettings.comment_require) {
        if (this.order.comment.length === 0) {
          this.errors.comment = [this.$static('order.comment-require-error', 'Коментар обов\'язковий')];
          window.scrollTarget = document.querySelector('.comment-data')
          this.showError('', this.$static('order.comment-require-error', 'Коментар обов\'язковий'))
          return false
        }
      }
      return true;
    }
  },

  watch: {
    deliveryOptions(val) {
      if (val.length) {
        this.delivery.type = val[0]
      }
    },
    availabilityNotify: {
      handler() {
        if (this.onlyLead) {
          this.errors.min_price = false;
        }
      },
      deep: true
    }
  }
  // payment(val) {
  //   if (val.length) {
  //     let payDefault = val.find(i => i.settings.default);
  //     if (payDefault) {
  //       this.order.pay_type = payDefault
  //     } else {
  //       this.order.pay_type = val[0]
  //     }
  //   }
  // }

}

</script>

<style scoped>
.order-total.error {
  color: red;
  font-weight: bold;
}

.not-allowed {
  opacity: 0.42;
  pointer-events: none;
}
</style>
