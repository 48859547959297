<template>
  <div class="title-heading">
    <h1>{{ $static('cabinet.title3', 'Особисті дані') }}</h1>
  </div>
  <div class="personal-settings">
    <div class="personal-setting-holder">
      <div class=" panel-bordered">
        <div class="form-inline">
          <div class="form-group">
            <label class="label-custom">{{ $static('cabinet.field5', 'Ім‘я') }} <span>*</span></label>
            <input type="text" class="form-control custom-input"
              :placeholder="$static('cabinet.field-name', 'Введіть ваше ім’я')" v-model="name">
          </div>
          <div class="form-group">
            <label class="label-custom">{{ $static('cabinet.field6', 'Прізвище') }} <span>*</span></label>
            <input type="text" class="form-control custom-input"
              :placeholder="$static('cabinet.field-soname', 'Введіть ваше прізвище')" v-model="surname">
          </div>
          <div class="form-group">
            <label class="label-custom">{{ $static('cabinet.field7', 'Телефон') }} <span>*</span></label>
            <input type="text" class="form-control custom-input" placeholder="+380" v-model="phone">
          </div>
          <div class="form-group">
            <label class="label-custom">{{ $static('cabinet.field8', 'Email') }}</label>
            <input type="text" class="form-control custom-input" placeholder="somemail@gmail.com" v-model="email">
          </div>
          <!--          <div class="form-group">-->
          <!--            <label class="label-custom">Мова інтерфейсу <span>*</span></label>-->
          <!--            <input type="text" class="form-control custom-input" value="Українська">-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div class="button-holder" @click="save">
      <button class="default-btn btn-no-icon">{{ $static('cabinet.btn-save', 'Зберегти зміни') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CabinetInfo',
  computed: {
    account() {
      return this.$store.getters.getAccount
    },
    name: {
      get() {
        return this.account?.info?.name ? this.account.info.name : ''
      },
      set(value) {
        this.account.info = Object.assign({}, this.account.info, { name: value })
      }
    },
    surname: {
      get() {
        return this.account?.info?.surname ? this.account.info.surname : ''
      },
      set(value) {
        this.account.info = Object.assign({}, this.account.info, { surname: value })
      }
    },
    email: {
      get() {
        return this.account?.info?.email ? this.account.info.email : ''
      },
      set(value) {
        this.account.info = Object.assign({}, this.account.info, { email: value })
      }
    },
    phone: {
      get() {
        return this.account?.info?.phone ? this.account.info.phone : ''
      },
      set(value) {
        this.account.info = Object.assign({}, this.account.info, { phone: value })
      }
    }
  },
  mounted() {
    let ok = false
    let userInfo = localStorage.getItem('user_info')
    let headers = {};
    let initCache = localStorage.getItem('initData');
    if (initCache && initCache.length) {
      headers.initData = initCache;
      ok = true
    }
    let body = {}
    if (userInfo) {
      body.user = JSON.parse(userInfo)
      ok = true
    }
    if (!ok) {
      this.$router.push({ name: 'AuthRequire' })
    }
  },
  methods: {
    save() {
      if (!this.account) {
        return
      }
      let ok = false;
      let userInfo = localStorage.getItem('user_info');
      let headers = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok = true
      }
      let body = this.account
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        ok = true
      }
      if (!ok) {
        return
      }
      return fetch(
        '/public/account/save',
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: headers
        }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.commit('setAccount', json.result)
        }
        if (json && json.file_count) {
          this.$store.commit('setFileCount', json.file_count)
        }
        if (json?.error) {
          window.localStorage.removeItem('user_info')
        }
      })
    },
  }
}
</script>
